import { Controller } from "stimulus"

let pageSize = 5;
let incremSlide = 5;
let startPage = 0;
let numberPage = 0;
let projectsSelectionCounter = 0;
let maxProjectsSelection = 0;

const paginationBuilder = function () {
  // pagination helpers methods
  const slide = function(){
    $("#pagin_bottom li").hide();
    $("#pagin_top li").hide();
    
    for(var t=startPage;t<incremSlide;t++){
      $("#pagin_bottom li").eq(t+1).show();
      $("#pagin_top li").eq(t+1).show();
    }
    if(startPage == 0){
      next.show();
      prev.hide();
    }else if(numberPage == totalSlidepPage ){
      next.hide();
      prev.show();
    }else{
      next.show();
      prev.show();
    }    
  }

  const showPage = function(page) {
      $(".line-content").hide();
      $(".line-content.visible").each(function(n) {
          if (n >= pageSize * (page - 1) && n < pageSize * page)
              $(this).show();
      });
  }

  //Pagination
  let pageCount =  $(".line-content.visible").length / pageSize;
  let totalSlidepPage = Math.floor(pageCount / incremSlide);
  
  $("#pagin_bottom").empty();
  $("#pagin_top").empty();
  for(var i = 0 ; i<pageCount;i++){
      $("#pagin_bottom").append('<li class="page-item"><a class="page-link"<a style="cursor: pointer;">'+(i+1)+'</a></li> ');
      $("#pagin_top").append('<li class="page-item"><a class="page-link"<a style="cursor: pointer;">'+(i+1)+'</a></li> ');
      if(i>pageSize){
        $("#pagin_bottom li").eq(i).hide();
        $("#pagin_top li").eq(i).hide();
      }
  }

  if (pageCount > incremSlide) {
    var prev = $("<li/>").addClass("prev").html("Prev").click(function(){
      startPage-=5;
      incremSlide-=5;
      numberPage--;
      slide();
    });
    prev.hide();

    var next = $("<li/>").addClass("next").html("Next").click(function(){
      startPage+=5;
      incremSlide+=5;
      numberPage++;
      slide();
    });

    $("#pagin_top").prepend(prev).append(next);
    $("#pagin_bottom").prepend(prev).append(next);
  }

  $("#pagin_bottom li").first().find("a").addClass("current");
  $("#pagin_top li").first().find("a").addClass("current");

  showPage(1);
  $("#pagin_bottom li a").eq(0).addClass("current");
  $("#pagin_top li a").eq(0).addClass("current");

  $("#pagin_bottom li a").click(function() {
    $("#pagin_bottom li a").removeClass("current");
    $(this).addClass("current");
    showPage(parseInt($(this).text()));
  });
  $("#pagin_top li a").click(function() {
    $("#pagin_top li a").removeClass("current");
    $(this).addClass("current");
    showPage(parseInt($(this).text()));
  });
}

export default class extends Controller {

  static targets = ["formBuilder", "searchCounter"]

  connect () {
    maxProjectsSelection = this.formBuilderTarget.dataset["maxProjectsSelection"];
    $.each($("[id*=selected_projects_]"), function(_index, input) { if (input.checked == true ) { projectsSelectionCounter++; } });
    // console.log(maxProjectsSelection);
    // console.log(projectsSelectionCounter);
    paginationBuilder();
  }

  selectedProject (event) {
    let parent = event.currentTarget.closest(".project_details_box");
    if ( event.currentTarget.checked == false ) {
      projectsSelectionCounter--;
      // console.log(projectsSelectionCounter);
      $(".notice-container").hide();
      if ( $(parent).find("#shifts").length > 0 ) { $($(parent).find("#shifts")[0]).hide(); }
      if ( $(parent).find("#schedules").length > 0 ) { $($(parent).find("#schedules")[0]).hide(); }

      if (projectsSelectionCounter > 1){
        $(parent).find("#selected_projects_order_").hide();
      } else {
        $.each(
          $.map(
            $(parent.closest("#projectsSelectionForm")).find(".project_details_box"), 
            function(project_details_box) { 
              return $(project_details_box).find("#selected_projects_order_")[0] 
            }
          ),
          function(_index, selected_project_order){
            $(selected_project_order).hide();
          }
        ) 
      }
    } else {
      if ( maxProjectsSelection != "" && projectsSelectionCounter + 1 > maxProjectsSelection ) {
        $(".notice-container").show();
        event.currentTarget.checked = false;
      } else {
        projectsSelectionCounter++;
        // console.log(projectsSelectionCounter);
        if ( $(parent).find("#shifts").length > 0 ) { $($(parent).find("#shifts")[0]).show(); }
        if ( $(parent).find("#schedules").length > 0 ) { $($(parent).find("#schedules")[0]).show(); }
      }

      if (projectsSelectionCounter > 1) {
        $.each(
          $.map(
            $(parent.closest("#projectsSelectionForm")).find(".project_details_box"), 
            function(project_details_box) { 
              if ($(project_details_box).find(".project")[0].checked === true)
                return $(project_details_box).find("#selected_projects_order_")[0] 
            }
          ),
          function(_index, selected_project_order){
            $(selected_project_order).show();
          }
        )       
      }
    }
  }

  selectedProgramShifts (event) {
    let parent = (event.currentTarget || input).closest(".container");
    let shift_schedules = $(parent).find("#shift_" + event.currentTarget.value + "_schedules");
    let shift_locations = $(parent).find("#shift_" + event.currentTarget.value + "_locations");
    parent = $(event.currentTarget).closest(".container");
    let near_inputs = $(parent[0]).find(".program_shift");

    if ( event.currentTarget.checked == false ) {
      if ( shift_schedules.length > 0 ) {
        shift_schedules.hide();
        $.each(shift_schedules.find("input"), function(_index, schedule) {
          schedule.checked = false;
          if (schedule.id.includes("no_preference") == true) { schedule.checked = true }
        })
        $.each(shift_schedules.find("[id$='order_']"), function(_index, schedule_order) {
          $(schedule_order).hide();
          $(schedule_order).prop('selectedIndex', 0);
        })
      }
      if ( shift_locations.length > 0 ) {
        shift_locations.hide();
        $.each(shift_locations.find("input"), function(_index, location) {
          location.checked = false
          if (location.id.includes("no_preference") == true) { location.checked = true }
        })
        $.each(shift_locations.find("[id$='order_']"), function(_index, location_order) {
          $(location_order).hide();
          $(location_order).prop('selectedIndex', 0);
        })
      }

      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).hide();
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).prop('selectedIndex', 0);
 
      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked === 1 ) {
        $.each(near_inputs, function(_index, input) {
          $($(input.parentElement).find("#" + input.id + "order_")[0]).hide();
          $($(input.parentElement).find("#" + input.id + "order_")[0]).prop('selectedIndex', 0);
        });
      }

      let all_unchecked = true
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { all_unchecked = false; } });
      if ( all_unchecked ) {
        let cleaner = $(event.currentTarget.closest(".container")).find("#" + event.currentTarget.id + "no_preference");
        if ( cleaner.length > 0 ) { cleaner[0].checked = true }
      }
    } else {
      if ( shift_schedules.length > 0 ) { shift_schedules.show(); }
      if ( shift_locations.length > 0 ) { shift_locations.show(); }

      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked > 1 ) {
        $.each(near_inputs, function(_index, input) {
          if (input.checked) { $($(input.parentElement).find("#" + input.id + "order_")[0]).show(); }
        });
      }

      let cleaner = $(event.currentTarget.closest(".container")).find("#" + event.currentTarget.id + "no_preference");
      if ( cleaner.length > 0 ) { cleaner[0].checked = false }
    }
  }

  selectedProgramShiftSchedules (event) {
    let parent = $(event.currentTarget).closest(".container");
    let near_inputs = $(parent[0]).find(".program_shift_schedule");

    if ( event.currentTarget.checked == false ) {
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).hide();
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).prop('selectedIndex', 0);

      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked === 1 ) {
        $.each(near_inputs, function(_index, input) {
          $($(input.parentElement).find("#" + input.id + "order_")[0]).hide();
          $($(input.parentElement).find("#" + input.id + "order_")[0]).prop('selectedIndex', 0);
        });
      }

      let all_unchecked = true
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { all_unchecked = false; } });
      if ( all_unchecked ) {
        let cleaner = $(event.currentTarget.closest(".container")).find("#" + event.currentTarget.id + "no_preference");
        if ( cleaner.length > 0 ) { cleaner[0].checked = true }
      }
    } 
    else {
      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked > 1 ) {
        $.each(near_inputs, function(_index, input) {
          if (input.checked) { $($(input.parentElement).find("#" + input.id + "order_")[0]).show(); }
        });
      }

      let cleaner = $(event.currentTarget.closest(".container")).find("#" + event.currentTarget.id + "no_preference");
      if ( cleaner.length > 0 ) { cleaner[0].checked = false }
    }
  }

  selectedProgramShiftLocations (event) {
    let parent = $(event.currentTarget).closest(".container");
    let near_inputs = $(parent[0]).find(".program_shift_location");

    if ( event.currentTarget.checked == false ) {
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).hide();
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).prop('selectedIndex', 0);

      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked === 1 ) {
        $.each(near_inputs, function(_index, input) {
          $($(input.parentElement).find("#" + input.id + "order_")[0]).hide();
          $($(input.parentElement).find("#" + input.id + "order_")[0]).prop('selectedIndex', 0);
        });
      }

      let all_unchecked = true
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { all_unchecked = false; } });
      if ( all_unchecked ) {
        let cleaner = $(event.currentTarget.closest(".container")).find("#" + event.currentTarget.id + "no_preference");
        if ( cleaner.length > 0 ) { cleaner[0].checked = true }
      }
    } else {
      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked > 1 ) {
        $.each(near_inputs, function(_index, input) {
          if (input.checked) { $($(input.parentElement).find("#" + input.id + "order_")[0]).show(); }
        });
      }

      let cleaner = $(event.currentTarget.closest(".container")).find("#" + event.currentTarget.id + "no_preference");
      if ( cleaner.length > 0 ) { cleaner[0].checked = false }
    }
  }

  selectedProgramSchedules (event) {
    let parent = $(event.currentTarget).closest(".container");
    let near_inputs = $(parent[0]).find(".program_schedule");

    if ( event.currentTarget.checked == false ) {
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).hide();
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).prop('selectedIndex', 0);

      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked === 1 ) {
        $.each(near_inputs, function(_index, input) {
          $($(input.parentElement).find("#" + input.id + "order_")[0]).hide();
          $($(input.parentElement).find("#" + input.id + "order_")[0]).prop('selectedIndex', 0);
        });
      }

      let all_unchecked = true;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { all_unchecked = false; } });
      if ( all_unchecked ) {
        let cleaner = $(event.currentTarget.closest(".container")).find("#" + event.currentTarget.id + "no_preference");
        if ( cleaner.length > 0 ) { cleaner[0].checked = true }
      }
    } else {
      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked > 1 ) {
        $.each(near_inputs, function(_index, input) {
          if (input.checked) { $($(input.parentElement).find("#" + input.id + "order_")[0]).show(); }
        });
      }

      let cleaner = $(event.currentTarget.closest(".container")).find("#" + event.currentTarget.id + "no_preference");
      if ( cleaner.length > 0 ) { cleaner[0].checked = false }
    }
  }

  no_preference_shifts_check (event) {
    let parent = $(event.currentTarget).closest(".container");
    let near_inputs = $(parent[0]).find("input:checkbox");
    if (near_inputs[near_inputs.length - 1].checked == true) {
      near_inputs.splice(-1,1);
      $.each(near_inputs, function(_index, input) {
        input.checked = false;
        input.dispatchEvent(new Event('change'));
      });
      $.each(near_inputs, function(_index, input) {
        if(input.id.includes("no_preference")) { input.checked =true }
      });
    } else {
      near_inputs[near_inputs.length - 1].checked = true;
    }
  }

  no_preference_check (event) {
    let parent = $(event.currentTarget).closest(".container");
    let near_inputs = $(parent[0]).find("input:checkbox");
    if (near_inputs[near_inputs.length - 1].checked == true) {
      near_inputs.splice(-1,1);
      $.each(near_inputs, function(_index, input) { input.checked = false; input.dispatchEvent(new Event('change')); });
    } else{
      near_inputs[near_inputs.length - 1].checked = true;
    }
  }

  searchInput (event) {
    const search_text = event.currentTarget.value.toLowerCase();
    if (search_text.length == 0) { $(".line-content").each(function(_index, projectBox) { $(projectBox).addClass("visible"); }); }

    var searchCounterActualValue = 0;
    $(".line-content").each(function(_index, projectBox) {
      const projectBoxText = $(projectBox).text();
      const lowerText = projectBoxText.toLowerCase();
      if ( lowerText.includes(search_text) ){
        $(projectBox).addClass("visible");
        searchCounterActualValue = searchCounterActualValue + 1;
      } else {
        $(projectBox).removeClass("visible");
      }
    });
    paginationBuilder();
    $(this.searchCounterTarget).html(searchCounterActualValue);
  }
}