import { Controller } from "stimulus"

import $ from 'jquery';
import 'slick-carousel/slick/slick.js';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

export default class extends Controller {

  connect() {
    $("#slickSlider").each( function( _index, element ){
      if ( element.classList.contains("slick-initialized") === false ) {
        let size = parseInt(element.dataset["size"]);
        if (size > 3) { size = 3 };

        $(element).slick({
          slidesToShow: size,
          slidesToScroll: 1,
          autoplay: true,
          prevArrow: $(".next_btn"),
          nextArrow: $(".previous_btn"),
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: size
              }
            }
          ]
        })
      }
    });
  }

  disconnect() {
    $("#slickSlider").each((_index, element) => {
      if (element.classList.contains("slick-initialized") === true) {
        $(element).slick("unslick");
      }
    });
  }
}
