import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// import Flatpickr
import Flatpickr from 'stimulus-flatpickr';

// import a theme (could be in your main CSS entry too...
// https://flatpickr.js.org/themes/
require("flatpickr/dist/flatpickr.css");

// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr);