require("jquery-ui");

// bootstrap
require("bootstrap/dist/js/bootstrap");
require("bootstrap/dist/css/bootstrap");

// Stylish fonts and icons
require("@fortawesome/fontawesome-free");

// Stylish alerts
require("./rails_sweetalert2");

// Carousel slick lib
require("slick-carousel");

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
// require("channels");

// Load Stimulus
require("./stimulus");

// Load trix for RichText
require("trix");
require("@rails/actiontext");

// Stylish Trix
require("./trix-costum");

// JQuery Star-Rating
require("rater-js/lib/rater-js");
require("rater-js/lib/style.css");

// D3 lib
require("d3");

// JQuery cookies
require("js-cookie");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// JSON HTML Editor
// https://github.com/josdejong/jsoneditor
// require('jsoneditor/dist/jsoneditor.min.css');
// const JSONEditor = require('jsoneditor')
// window.JSONEditor = JSONEditor
