import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dateFilter"]

  connect() {
    this.dateFilterTargets.forEach(function(date_picker){
      let date_input = $(date_picker.parentElement).find("input")[0]
      if (date_input === undefined) { date_input = $(date_picker.closest(".row")).find("input")[0] }
      if (date_input.value.length > 0){
        $(date_picker).show();
      } else {
        $(date_picker).hide();
      }
    });

    $('.image-sortable').sortable({
      axis: 'y',
      items: '.image'
    });

    $('.add-image').click(function () {
      $('.image-sortable').append('<div class="image"><input multiple="multiple" type="file" name="album[images][]"></div>');
    });

    $('.remove-image').click(function () {
      $(this).parent('.image').remove();
    });
  }

  open_href(event) {
    window.location.href = event.currentTarget.dataset["href"];
  };

  update_banner(event) {
    let element = event.currentTarget;
    if (element.files && element.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) { $('#banner_image').attr('src', e.target.result); };
      reader.readAsDataURL(element.files[0]);
    }
  };

  update_banner_card(event) {
    let element = event.currentTarget;
    if (element.files && element.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) { $('#banner_card_image').attr('src', e.target.result); };
      reader.readAsDataURL(element.files[0]);
    }
  };

  update_from_the_past_banner(event) {
    let element = event.currentTarget;    
    if (element.files && element.files[0]) {
      var targetOutput = element.closest(".col").getElementsByTagName("img").item(0);
      var reader = new FileReader();
      reader.onload = function (e) { targetOutput.src = e.target.result};
      reader.readAsDataURL(element.files[0]);
    }
  };

  clear_date(event) {
    let element = $(event.currentTarget.closest(".row")).find('.flatpickr-input')[0];
    element._flatpickr.clear();
    element.value = "";
  };

  add_field(event) {
    let time = new Date().getTime();
    let regexp = new RegExp($(event.currentTarget).data('id'), 'g')
    $(event.currentTarget).before(
      $(event.currentTarget).data('fields').replace(regexp, time)
    );
    event.preventDefault()
  };

  remove_field(event) {
    $(event.currentTarget).prev('input[type=hidden').val('1')
    $(event.currentTarget).closest('li').hide();
    event.preventDefault()
  };
}
