import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["submit", "addQuestion"]

  add_field(event) {
    let form = $(event.currentTarget).closest("form")[0];
    if ($(form).find("li").length < 3) {
      $(event.currentTarget).before($(event.currentTarget).data('fields'));

      if ($(form).find("li").length === 3) {
        $(this.addQuestionTarget).hide();
      }
      event.preventDefault();
    }
  };

  remove_field(event) {
    $(event.currentTarget).closest('li').remove();
    $(this.addQuestionTarget).show();
    event.preventDefault();
  };
}