import { Controller } from "stimulus"
var rater = require("rater-js");

export default class extends Controller {
  static targets = ["rating"];

  connect() {
    $.each(this.ratingTargets, function(_index, rating) {
      var starRater = rater({
          element: rating,
          starSize: 25,
          max: 5,
          showToolTip: true,
          rateCallback: function rateCallback(currentRating, done) {
            $("#question-" + rating.dataset.id).val(currentRating);
            starRater.setRating(currentRating);
            done();
          }
        });
    });
  }

}
