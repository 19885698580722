import { Controller } from "stimulus";

const set_new_style = (target, show) => {
  if (show) {
    $(target).css({ display: "block" });
  } else {
    $(target).css({ display: "none" });
  }
}

const reset_value = (target) => { target.value = "" }

export default class extends Controller {
  static targets = ["legal_guardian_form", "proof_of_residence", "health_restrictions", "risk_group", "scholarship_payment", "disclosure_authorization"]

  connect() {
    const is_minor = this.legal_guardian_formTarget.dataset["minor"];
    set_new_style(this.legal_guardian_formTarget, is_minor === "true");

    const is_resident = this.proof_of_residenceTarget.dataset["is_cascais_resident"];
    set_new_style(this.proof_of_residenceTarget, is_resident === "true");

    const health_restrictions_check = this.health_restrictionsTarget.dataset["health_restrictions_check"];
    set_new_style(this.health_restrictionsTarget, health_restrictions_check === "Sim");

    const risk_group_check = this.risk_groupTarget.dataset["risk_group_check"];
    set_new_style(this.risk_groupTarget, risk_group_check === "Sim");

    const allow_image_divulgation = this.disclosure_authorizationTarget.dataset["allow_image_divulgation"];
    set_new_style(this.disclosure_authorizationTarget, allow_image_divulgation == "true");
  }

  clear_date(event) {
    const element = $($(event.currentTarget.parentElement).find('.flatpickr-input'))[0]
    const flatpickr = element.flatpickr();
    flatpickr.clear();
    element.value = "";
  }

  birthday_changed(event) {
    const today = new Date();
    const birthday_parts = event.currentTarget.value.split('-');
    // Warning: Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // - January - 0, February - 1, etc.
    const birthday = new Date(birthday_parts[2], birthday_parts[1] - 1, birthday_parts[0]);
    let age = today.getFullYear() - birthday.getFullYear();
    if (today.getMonth() < birthday.getMonth() || (today.getMonth() == birthday.getMonth() && today.getDate() < birthday.getDay())) {
      age--;
    };
    set_new_style(this.legal_guardian_formTarget, age < 18);
    if (age < 18) {
      $(this.disclosure_authorizationTarget.parentElement).find("select")[0].value = "Não";
    }
  }

  cascais_or_student_resident_changed(event) {
    set_new_style(this.proof_of_residenceTarget, event.currentTarget.value === "Sim");
  }

  image_disclosure_authorization_changed(event) {
    set_new_style(this.disclosure_authorizationTarget, event.currentTarget.value === "Sim");
  }

  health_restrictions_check_changed(event) {
    set_new_style(this.health_restrictionsTarget, event.currentTarget.value === "Sim");
  }

  risk_group_check_changed(event) {
    set_new_style(this.risk_groupTarget, event.currentTarget.value === "Sim");
  }

  clear_date(event) {
    const element = $($(event.currentTarget.parentElement).find('.flatpickr-input'))[0]
    const flatpickr = element.flatpickr();
    flatpickr.clear();
    element.value = "";
  }

  open_href(event) {
    window.location.href = event.currentTarget.dataset["href"];
  };
}
