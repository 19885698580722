import { Controller } from "stimulus"

export default class extends Controller {

  selectedProgramShifts (event) {
    let parent = (event.currentTarget || input).closest(".container");
    let shift_schedules = $(parent).find("#shift_" + event.currentTarget.value + "_schedules");
    let shift_locations = $(parent).find("#shift_" + event.currentTarget.value + "_locations");
    parent = $(event.currentTarget).closest(".container-fluid");
    let near_inputs = $(parent[0]).find(".program_shift");

    if ( event.currentTarget.checked == false ) {
      if ( shift_schedules.length > 0 ) {
        shift_schedules.hide();
        $.each(shift_schedules.find("input"), function(_index, schedule) {
          schedule.checked = false;
          if (schedule.id.includes("no_preference") == true) { schedule.checked = true }
        })
        $.each(shift_schedules.find("[id$='order_']"), function(_index, schedule_order) {
          $(schedule_order).hide();
          $(schedule_order).prop('selectedIndex', 0);
        })
      }
      if ( shift_locations.length > 0 ) {
        shift_locations.hide();
        $.each(shift_locations.find("input"), function(_index, location) {
          location.checked = false
          if (location.id.includes("no_preference") == true) { location.checked = true }
        })
        $.each(shift_locations.find("[id$='order_']"), function(_index, location_order) {
          $(location_order).hide();
          $(location_order).prop('selectedIndex', 0);
        })
      }

      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).hide();
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).prop('selectedIndex', 0);
 
      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked === 1 ) {
        $.each(near_inputs, function(_index, input) {
          $($(input.parentElement).find("#" + input.id + "order_")[0]).hide();
          $($(input.parentElement).find("#" + input.id + "order_")[0]).prop('selectedIndex', 0);
        });
      }

      let all_unchecked = true
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { all_unchecked = false; } });
      if ( all_unchecked ) {
        let cleaner = $(event.currentTarget.closest(".container-fluid")).find("#" + event.currentTarget.id + "no_preference");
        if ( cleaner.length > 0 ) { cleaner[0].checked = true }
      }
    } else {
      if ( shift_schedules.length > 0 ) { shift_schedules.show(); }
      if ( shift_locations.length > 0 ) { shift_locations.show(); }

      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked > 1 ) {
        $.each(near_inputs, function(_index, input) {
          if (input.checked) { $($(input.parentElement).find("#" + input.id + "order_")[0]).show(); }
        });
      }

      let cleaner = $(event.currentTarget.closest(".container-fluid")).find("#" + event.currentTarget.id + "no_preference");
      if ( cleaner.length > 0 ) { cleaner[0].checked = false }
    }
  }

  selectedProgramShiftSchedules (event) {
    let parent = $(event.currentTarget).closest(".container-fluid");
    let near_inputs = $(parent[0]).find(".program_shift_schedule");

    if ( event.currentTarget.checked == false ) {
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).hide();
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).prop('selectedIndex', 0);

      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked === 1 ) {
        $.each(near_inputs, function(_index, input) {
          $($(input.parentElement).find("#" + input.id + "order_")[0]).hide();
          $($(input.parentElement).find("#" + input.id + "order_")[0]).prop('selectedIndex', 0);
        });
      }

      let all_unchecked = true
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { all_unchecked = false; } });
      if ( all_unchecked ) {
        let cleaner = $(event.currentTarget.closest(".container-fluid")).find("#" + event.currentTarget.id + "no_preference");
        if ( cleaner.length > 0 ) { cleaner[0].checked = true }
      }
    } else {
      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked > 1 ) {
        $.each(near_inputs, function(_index, input) {
          if (input.checked) { $($(input.parentElement).find("#" + input.id + "order_")[0]).show(); }
        });
      }

      let cleaner = $(event.currentTarget.closest(".container-fluid")).find("#" + event.currentTarget.id + "no_preference");
      if ( cleaner.length > 0 ) { cleaner[0].checked = false }
    }
  }

  selectedProgramShiftLocations (event) {
    let parent = $(event.currentTarget).closest(".container-fluid");
    let near_inputs = $(parent[0]).find(".program_shift_location");

    if ( event.currentTarget.checked == false ) {
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).hide();
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).prop('selectedIndex', 0);

      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked === 1 ) {
        $.each(near_inputs, function(_index, input) {
          $($(input.parentElement).find("#" + input.id + "order_")[0]).hide();
          $($(input.parentElement).find("#" + input.id + "order_")[0]).prop('selectedIndex', 0);
        });
      }

      let all_unchecked = true
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { all_unchecked = false; } });
      if ( all_unchecked ) {
        let cleaner = $(event.currentTarget.closest(".container-fluid")).find("#" + event.currentTarget.id + "no_preference");
        if ( cleaner.length > 0 ) { cleaner[0].checked = true }
      }
    } else {
      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked > 1 ) {
        $.each(near_inputs, function(_index, input) {
          if (input.checked) { $($(input.parentElement).find("#" + input.id + "order_")[0]).show(); }
        });
      }

      let cleaner = $(event.currentTarget.closest(".container-fluid")).find("#" + event.currentTarget.id + "no_preference");
      if ( cleaner.length > 0 ) { cleaner[0].checked = false }
    }
  }

  selectedProgramSchedules (event) {
    let parent = $(event.currentTarget).closest(".container-fluid");
    let near_inputs = $(parent[0]).find(".program_schedule");

    if ( event.currentTarget.checked == false ) {
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).hide();
      $($(event.currentTarget.parentElement).find("#" + event.currentTarget.id + "order_")[0]).prop('selectedIndex', 0);

      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked === 1 ) {
        $.each(near_inputs, function(_index, input) {
          $($(input.parentElement).find("#" + input.id + "order_")[0]).hide();
          $($(input.parentElement).find("#" + input.id + "order_")[0]).prop('selectedIndex', 0);
        });
      }

      let all_unchecked = true;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { all_unchecked = false; } });
      if ( all_unchecked ) {
        let cleaner = $(event.currentTarget.closest(".container-fluid")).find("#" + event.currentTarget.id + "no_preference");
        if ( cleaner.length > 0 ) { cleaner[0].checked = true }
      }
    } else {
      let checked = 0;
      $.each(near_inputs, function(_index, input) { if(input.checked === true) { checked += 1 } });
      if ( checked > 1 ) {
        $.each(near_inputs, function(_index, input) {
          if (input.checked) { $($(input.parentElement).find("#" + input.id + "order_")[0]).show(); }
        });
      }

      let cleaner = $(event.currentTarget.closest(".container-fluid")).find("#" + event.currentTarget.id + "no_preference");
      if ( cleaner.length > 0 ) { cleaner[0].checked = false }
    }
  }

  no_preference_shifts_check (event) {
    let parent = $(event.currentTarget).closest("#shifts");
    let near_inputs = $(parent[0]).find("[id$='shifts_']");
    $.each(near_inputs, function(_index, input) {
      input.checked = false;
      input.dispatchEvent(new Event('change'));
    });
  }

  no_preference_location_check (event) {
    let parent = $(event.currentTarget).closest("[id$='locations']");
    let near_inputs = $(parent[0]).find("[id$='locations_']");
    $.each(near_inputs, function(_index, input) {
      input.checked = false;
      input.dispatchEvent(new Event('change'));
    });
  }

  no_preference_check (event) {
    let parent = $(event.currentTarget).closest("[id$='schedules']");
    let near_inputs = $(parent[0]).find("[id$='schedules_']");
    $.each(near_inputs, function(_index, input) {
      input.checked = false;
      input.dispatchEvent(new Event('change'));
    });
  }
}