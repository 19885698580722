import { Controller } from "stimulus"
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ["formBuilder", "formRender"]

  connect() {
    $('#jotFormModalCenter').modal({ show: true, backdrop: "static" });
  }

  turnOnJotForm = (_event) => {
    JotformAnywhere.launchFormBuilder({
      builderMaskColor: "#FFFFFF",
      openInModal: false,
      insertTo: "#formBuilder",
      width: "100%"
    });

    // JotformAnywhere.subscribe("jotform.formLoaded", (_response) => {
    //   console.log("Loaded!!");
    // });

    JotformAnywhere.subscribe("jotform.formCreated", (response) => {
      $.ajax({
        type: "POST",
        url: this.formBuilderTarget.dataset["createUrl"],
        data: { dynamic_form_id: response.formID },
        success: function(response){
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            timer: 2000,
            timerProgressBar: true
          }).then(function() {
            window.location.href = response.next_url;
          })
        }
      });
    });
  }

  openJotFormWebSite = (_event) => {
    window.open('https://eu.jotform.com/login/', '_blank');
  }

  jotFormModalClosed = (event) => {
    window.location.href = event.currentTarget.dataset.gobackurl;
  }

}