import { Controller } from "stimulus"

export default class extends Controller {
  open_root(_event) {
    window.location.href = "/";
  };

  open_href(event) {
    window.location.href = event.currentTarget.getAttribute("href");
  };
}