import { Controller } from "stimulus";

import $ from "jquery";
import "slick-carousel/slick/slick.js";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

export default class extends Controller {

  connect() {
    var initSlickSlider = function(element, type) {
      if ( element.classList.contains("slick-initialized") === false ) {
        let size = parseInt(element.dataset["size"]);
        if (size > 3) { size = 3 };
  
        $(element).slick({
          slidesToShow: size,
          slidesToScroll: 1,
          nextArrow: $(".previous_" + type + "_btn"),
          prevArrow: $(".next_" + type + "_btn"),
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: size
              }
            }
          ]
        })
      }
    };

    $("#programsSlickSlider").each( function( _index, element ){
      initSlickSlider(element, "program");
    });

    $("#eventsSlickSlider").each( function( _index, element ){
      initSlickSlider(element, "event");
    });

    $("[id^='slickSlider-']").each( function( index, element ){
      if ( element.classList.contains("slick-initialized") == false ) {
        let size = parseInt(element.dataset["categorySize"]);
        if (size > 3) { size = 3 };

        $(element).slick({
          slidesToShow: size,
          slidesToScroll: 1,
          nextArrow: $(".previous_btn-" + (index)),
          prevArrow: $(".next_btn-" + (index)),
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: size
              }
            }
          ]
        })
      }
    });
  }

  disconnect() {
    var unslickSlickSlider = function(element) {
      if (element.classList.contains("slick-initialized") === true) {
        $(element).slick("unslick");
      }
    }

    $("#programsSlickSlider").each((_index, element) => {
      unslickSlickSlider(element);
    });

    $("#eventsSlickSlider").each((_index, element) => {
      unslickSlickSlider(element);
    });

    $("[id^='slickSlider-']").each((_index, element) => {
      if (element.classList.contains("slick-initialized") === true) {
        $(element).slick("unslick");
      }
    });
  }

  open_href(event) {
    window.location.href = event.currentTarget.dataset["href"];
  };

  open_href_new_tab(event) {
    window.open(
      event.currentTarget.dataset["href"],
      '_blank'
    );    
  };
}
