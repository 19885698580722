import { Controller } from "stimulus"

function repopulateDropDown(id, data) {
  $(id).empty();
  $(id).selected = "-1";
  for (let i = 0; i < data.length; i += 1) {
    $(id).append(`<option value="${data[i][1]}">${data[i][0]}</option>`);
  }
}

export default class extends Controller {
  static targets = ["user", "project", "form", "freguesia", "age", "program"]

  openHref(event) {
    window.location.href = event.currentTarget.dataset["href"];
  };

  changedSelectedProgram(event) {
    this.projectTarget.value = "";
    this.freguesiaTarget.value = "";
    this.ageTarget.value = "";
    this.userTarget.value = "";

    const program_id = event.currentTarget.value;
    $.ajax({
      url: "/administration/emails/filters",
      method: "GET",
      dataType: "json",
      data: { program_id },
      error(xhr, status, error) {
        console.error(`AJAX Error: ${status}${error}`);
      },
      success(response) {
        const unique_projects = response["content"]["unique_projects"];
        repopulateDropDown("#email_projects", unique_projects);

        const unique_freguesias = response["content"]["unique_freguesias"];
        repopulateDropDown("#email_freguesias", unique_freguesias);

        const unique_ages = response["content"]["unique_ages"];
        repopulateDropDown("#email_ages", unique_ages);

        const unique_users = response["content"]["unique_users"];
        repopulateDropDown("#email_users", unique_users);
      }
    });
  }

  changedSelectedProject(event) {
    this.freguesiaTarget.value = "";
    this.ageTarget.value = "";
    this.userTarget.value = "";

    const program_id = this.programTarget.value;
    const project_id = event.currentTarget.value;
    $.ajax({
      url: "/administration/emails/filters",
      method: "GET",
      dataType: "json",
      data: { project_id, program_id },
      error(xhr, status, error) {
        console.error(`AJAX Error: ${status}${error}`);
      },
      success(response) {
        const unique_freguesias = response["content"]["unique_freguesias"];
        repopulateDropDown("#email_freguesias", unique_freguesias);

        const unique_ages = response["content"]["unique_ages"];
        repopulateDropDown("#email_ages", unique_ages);

        const unique_users = response["content"]["unique_users"];
        repopulateDropDown("#email_users", unique_users);
      }
    });
  }

  changedSelectedFreguesia(event) {
    this.ageTarget.value = "";
    this.userTarget.value = "";

    const program_id = this.programTarget.value;
    const project_id = this.projectTarget.value;
    const freguesia = event.currentTarget.value;
    $.ajax({
      url: "/administration/emails/filters",
      method: "GET",
      dataType: "json",
      data: { program_id, project_id, freguesia },
      error(xhr, status, error) {
        console.error(`AJAX Error: ${status}${error}`);
      },
      success(response) {
        const unique_ages = response["content"]["unique_ages"];
        repopulateDropDown("#email_ages", unique_ages);

        const unique_users = response["content"]["unique_users"];
        repopulateDropDown("#email_users", unique_users);
      }
    });
  }

  changedSelectedAge(event) {
    this.userTarget.value = "";

    const program_id = this.programTarget.value;
    const project_id = this.projectTarget.value;
    const freguesia = this.freguesiaTarget.value;
    const age = event.currentTarget.value;
    $.ajax({
      url: "/administration/emails/filters",
      method: "GET",
      dataType: "json",
      data: { program_id, project_id, freguesia, age },
      error(xhr, status, error) {
        console.error(`AJAX Error: ${status}${error}`);
      },
      success(response) {
        const unique_users = response["content"]["unique_users"];
        repopulateDropDown("#email_users", unique_users);
      }
    });
  }

}
