import Flatpickr from 'stimulus-flatpickr'

// import a theme (could be in your main CSS entry too...
// https://flatpickr.js.org/themes/
import "flatpickr/dist/themes/airbnb.css"

// import the translation files and create a translation mapping
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
import { english } from "flatpickr/dist/l10n/default.js"

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  locales = {
    pt: Portuguese,
    en: english
  }

  // Global options
  // initialize() {
  //   this.config = {
  //     locale: this.locale,
  //     // enableTime: true,
  //     time_24hr: true
  //   };
  // }

  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      //...this.config, //spread options in case some where defined in initialize
      locale: this.locale
    };

    //always call super.connect()
    super.connect();
  }

  get locale() {
    if (this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller - https://flatpickr.js.org/events/
  // just add the function to your Stimulus Controller in camelCase without on: onChange -> change(){}
  change(selectedDates, _dateStr, instance) {
    if (selectedDates.lenght == 0 ) {
      $($(instance.input.parentElement).find(".dateInput__icon")[0]).hide();
    } else {
      $($(instance.input.parentElement).find(".dateInput__icon")[0]).show();
    }
  }
}
