import { Controller } from "stimulus"

export default class extends Controller {
  open_href(event) {
    window.location.href = event.currentTarget.dataset["href"];
  };

  clear_date(event) {
    const element = $($(event.currentTarget.parentElement).find('.flatpickr-input'))[0]
    const flatpickr = element.flatpickr();
    flatpickr.clear();
    element.value = "";
  }
}
