import { Controller } from "stimulus"

let pageSize = 5;
let incremSlide = 5;
let startPage = 0;
let numberPage = 0;

const paginationBuilder = function () {
  // pagination helpers methods
  const slide = function(){
    $("#pagin_bottom li").hide();
    
    for(var t=startPage;t<incremSlide;t++){ $("#pagin_bottom li").eq(t+1).show(); }
    if(startPage == 0){
      next.show();
      prev.hide();
    }else if(numberPage == totalSlidepPage ){
      next.hide();
      prev.show();
    }else{
      next.show();
      prev.show();
    }    
  }

  const showPage = function(page) {
    $(".line-content").hide();
    $(".line-content.visible").each(function(n) {
      if (n >= pageSize * (page - 1) && n < pageSize * page) { $(this).show(); }
    });
  }

  //Pagination
  let pageCount =  $(".line-content.visible").length / pageSize;
  let totalSlidepPage = Math.floor(pageCount / incremSlide);
  
  $("#pagin_bottom").empty();
  for(var i = 0 ; i<pageCount;i++){
    $("#pagin_bottom").append('<li class="page-item"><a class="page-link"<a style="cursor: pointer;">'+(i+1)+'</a></li> ');
    if(i>pageSize){ $("#pagin_bottom li").eq(i).hide(); }
  }

  if (pageCount > incremSlide) {
    var prev = $("<li/>").addClass("prev").html("Prev").click(function(){
      startPage-=5;
      incremSlide-=5;
      numberPage--;
      slide();
    });
    prev.hide();

    var next = $("<li/>").addClass("next").html("Next").click(function(){
      startPage+=5;
      incremSlide+=5;
      numberPage++;
      slide();
    });

    $("#pagin_bottom").prepend(prev).append(next);
  }

  $("#pagin_bottom li").first().find("a").addClass("current");

  showPage(1);
  $("#pagin_bottom li a").eq(0).addClass("current");

  $("#pagin_bottom li a").click(function() {
    $("#pagin_bottom li a").removeClass("current");
    $(this).addClass("current");
    showPage(parseInt($(this).text()));
  });
}

export default class extends Controller {
  static targets = ["searchCounter"];

  connect () {
    paginationBuilder();
  }

  searchInput (event) {
    const search_text = event.currentTarget.value.toLowerCase();
    if (search_text.length == 0) { $(".line-content").each(function(_index, projectBox) { $(projectBox).addClass("visible"); }); }

    var searchCounterActualValue = 0;
    $(".line-content").each(function(_index, projectBox) {
      const projectBoxText = $(projectBox).text();
      const lowerText = projectBoxText.toLowerCase();
      if ( lowerText.includes(search_text) ){
        $(projectBox).addClass("visible");
        searchCounterActualValue = searchCounterActualValue + 1;
      } else {
        $(projectBox).removeClass("visible");
      }
    });
    paginationBuilder();
    $(this.searchCounterTarget).html(searchCounterActualValue);
  }
}