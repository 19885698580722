import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dateFilter"]

  connect() {
    this.dateFilterTargets.forEach(function(date_picker){
      let date_input = $(date_picker.parentElement).find("input")[0]
      if (date_input.value.length > 0){
        $(date_picker).show();
      } else {
        $(date_picker).hide();
      }
    });
  }

  open_href(event) {
    window.location.href = event.currentTarget.dataset["href"];
  };

  update_banner(event) {
    let element = event.currentTarget;
    if (element.files && element.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) { $('#banner_image').attr('src', e.target.result); };
      reader.readAsDataURL(element.files[0]);
    }
  };

  update_banner_vertical(event) {
    let element = event.currentTarget;
    if (element.files && element.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) { $('#banner_vertical_image').attr('src', e.target.result); };
      reader.readAsDataURL(element.files[0]);
    }
  };

  clear_date(event) {
    let element = $($(event.currentTarget.parentElement).find('.flatpickr-input'))[0]
    element._flatpickr.clear();
    element.value = "";
  };

  add_field(event) {
    let time = new Date().getTime();
    let regexp = new RegExp($(event.currentTarget).data('id'), 'g')
    $(event.currentTarget).before(
      $(event.currentTarget).data('fields').replace(regexp, time)
    );
    event.preventDefault()
  };

  remove_field(event) {
    $(event.currentTarget).prev('input[type=hidden').val('1')
    $(event.currentTarget).closest('li').hide();
    event.preventDefault()
  };
}
