import { Controller } from "stimulus"

const set_new_style = (target, show) => {
  if (show) {
    $(target).css({ display: "block" });
  } else {
    $(target).css({ display: "none" });
  }
}

export default class extends Controller {

  static targets = [
    "acceptedOrNot", "lastUpdateInfo", "lastUpdateInfoSubmit",
    "cancelledInfo", "cancelledSubmit",
    "completedInfo", "completedSubmit"
  ]

  acceptedOrNotChange(event) {
    if (event.currentTarget.value === "") {
      $(this.lastUpdateInfoTarget).css({ display: "block" });
      $(this.lastUpdateInfoSubmitTarget).css({ display: "block" });
      return;
    }

    $(event.currentTarget.closest("form")).submit();
  }

  programCancelledChange(event) {
    if (event.currentTarget.value === "") {
      $(this.cancelledInfoTarget).hide();
      this.cancelledInfoTarget.value = "";
    } else {
      $(this.cancelledInfoTarget).show();
    }

    $(this.cancelledSubmitTarget).show();
  }

  programCompletedChange(event) {
    if (event.currentTarget.value === "true") {
      $(this.completedInfoTarget).show();
    } else {
      $(this.completedInfoTarget).hide();
    }

    $(this.completedSubmitTarget).show();
  }

  projectSelectedOrNotChange(event) {
    let form = event.currentTarget.closest("form");
    let projectSelectedOrNotInfo = $(form).find("#project_selected_or_not_info")[0]

    if (event.currentTarget.value === "") {
      let submitButton = $(form).find("#project_selected_or_not_submit")[0]
      $(submitButton).css({ display: "block" });
      $(projectSelectedOrNotInfo).css({ display: "block" });
      return;
    }

    projectSelectedOrNotInfo.value = "";
    $(form).submit();
  }

  projectCancelledChange(event) {
    let form = event.currentTarget.closest("form");
    let cancelledInfo = $(form).find("#cancelled_info")[0];

    if (event.currentTarget.value === "") {
      $(cancelledInfo).hide();
      cancelledInfo.value = "";
    } else {
      $(cancelledInfo).show();
    }

    $($(form).find("#cancelled_submit")[0]).show();
  }

  projectCompletedChange(event) {
    let form = event.currentTarget.closest("form");
    let completedInfo = $(form).find("#completed_info")[0]
    if (event.currentTarget.value === "true") {
      $(completedInfo).show();
    } else {
      $(completedInfo).hide();
    }
    $($(form).find("#project_completed_submit")[0]).show();
  }
}
