import { Controller } from "stimulus";

const d3 = require("d3");

const buildGraph = (divID, data, maxValue) => {
  // set the dimensions and margins of the graph
  var margin = {top: 30, right: 60, bottom: 70, left: 30},
  width = 300 - margin.left - margin.right,
  height = 300 - margin.top - margin.bottom;

  // append the svg object to the body of the page
  var svg = d3.select(divID, data)
  .append("svg")
  .attr("width", width + margin.left + margin.right)
  .attr("height", height + margin.top + margin.bottom)
  .append("g")
  .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

  // x axis
  var x = d3.scaleBand()
  .range([ 0, width ])
  .domain(data.map(function(d) { return d.Key; }))
  .padding(0.2);

  svg.append("g")
  .attr("transform", "translate(0," + height + ")")
  .call(d3.axisBottom(x))
  .selectAll("text")
  .attr("transform", "translate(-10,0)rotate(-45)")
  .style("text-anchor", "end");

  // y axis
  var y = d3.scaleLinear()
  .domain([0, maxValue])
  .range([ height, 0]);

  svg.append("g")
  .call(
    d3.axisLeft(y)
      .ticks(maxValue)
  );

  // bars
  svg.selectAll("mybar")
  .data(data)
  .enter()
  .append("rect")
  .attr("x", function(d) { return x(d.Key); })
  .attr("y", function(d) { return y(d.Value); })
  .attr("width", x.bandwidth())
  .attr("height", function(d) { return height - y(d.Value); })
  .attr("fill", "#69b3a2")
}

const maxBy = (arr, fn) => Math.max(...arr.map(typeof fn === 'function' ? fn : val => val[fn]));

export default class extends Controller {

  connect() {
    let data = JSON.parse($("#subscriptionsPart1Chart")[0].dataset.info)
    buildGraph("#subscriptionsPart1Chart", data, maxBy(data, 'Value'));

    data = JSON.parse($("#subscriptionsPart2Chart")[0].dataset.info)
    buildGraph("#subscriptionsPart2Chart", data, maxBy(data, 'Value'));
    
    data = JSON.parse($("#subscriptionsPart3Chart")[0].dataset.info)
    buildGraph("#subscriptionsPart3Chart", data, maxBy(data, 'Value'));

    data = JSON.parse($("#subscriptionsPart4Chart")[0].dataset.info)
    buildGraph("#subscriptionsPart4Chart", data, maxBy(data, 'Value'));

    data = JSON.parse($("#usersChart")[0].dataset.info)
    buildGraph("#usersChart", data, maxBy(data, 'Value'));
  }
}
