import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["filterable"];

  search(event) {
    let filter = event.currentTarget.value.toLowerCase();

    this.filterableTargets.forEach((element) => {
      $(element).toggle(
        $(element).text().toLowerCase().includes(filter)
      )
    });
  }
}