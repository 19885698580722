import Cookies from 'js-cookie'

const cookieKey = "_cascais_cookie-modal";
const expireDays = 7;

export const setAcceptedCookies = () => {
  Cookies.set(cookieKey, true, { expires: expireDays })
};

export const readAcceptedCookies = () => {
  return Cookies.get(cookieKey) === "true";
};
